@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");

* {
  box-sizing: border-box;
  font-family: Muli;
}

body {
  min-height: 100vh;
  background: linear-gradient(firebrick, white, rgb(135, 167, 235));

  scrollbar-width: none;
  -ms-overflow-style: none;

  transition: opacity 500ms linear;
  opacity: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    text-decoration: none;

    &:hover {
      color: white;
    }
  }

  button {
    margin: 5px;
    word-wrap: break-word;
    cursor: pointer;
  }
}

/* TOP LEVEL DIVS */

.dim-overlay {
  height: 100vh;

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  opacity: 0.2;
  transition: opacity 200ms linear;
}

.fork-me {
  z-index: 2;

  position: fixed;
  top: 0;
  right: 0;
}

.header {
  z-index: 1;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 6px;

  h1 {
    font-size: 55;
    margin: 0;
    color: black;
  }

  p {
    margin: 5px;
    font-weight: 900;
  }
}

.content-wrapper {
  z-index: 3;

  margin: 0 auto;
  max-width: 640px;
  display: flex;
  justify-content: center;
  position: relative;
  left: 5px;

  .middle-container {
    margin: 0 auto;
    max-width: 300px;

    position: relative;
    right: 0px; // adjust to make room for imessage log
    transition: transform 200ms ease-in-out;

    .middle-top {
      position: relative;
      z-index: 0;

      img {
        border-radius: 6px;
        cursor: pointer;
        user-select: none;

        &:active {
          transform: scale(95%);
        }
      }

      .peekaboo {
        z-index: -10;

        canvas {
          position: absolute;
          top: 163px;
          left: 0px;

          padding-left: 3px;
          pointer-events: none;
        }

        .listen-message {
          position: absolute;
          left: 10px;
          transition: opacity 175ms linear;
          font-weight: 900;
        }

        .loader {
          text-align: center;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 166px;

          width: 300px;
          height: 300px;
          transition: opacity 0ms linear;

          .loader-message {
            font-weight: 900;
            font-size: 12px;
          }

          .loader-wheel {
            border: 2px solid black;
            border-bottom: 6px solid #f3f3f3; /* Light grey */
            border-left: 6px solid white;
            border-right: 6px solid firebrick;
            border-top: 6px solid #3498db; /* Blue */
            border-radius: 50%;

            width: 20px;
            height: 20px;
            position: relative;
            top: -5px;
            left: 136px;
            animation: spin 2s linear infinite;
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }

    .middle-slide {
      margin: 10px;
      z-index: 1;
      position: relative;
      top: -10px;

      transition: transform 200ms ease-in;

      form {
        margin-top: 25px;
        width: 330px;
        height: 75px;

        position: relative;
        right: 5px;

        display: flex;
        justify-content: center;

        button {
          cursor: pointer;
          border: 0;
          background: transparent;
          position: relative;
          top: 3px;
          right: 35px;

          &:hover {
            transform: scale(120%);
          }

          &:active {
            transform: scale(90%);
          }

          &:focus {
            outline: none;
          }
        }

        .form-buttons {
          opacity: 80%;
          height: 69px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        textarea {
          width: 300px;
          padding: 8px;
          padding-right: 24px;
          font-size: 16px;
          background: rgba(255, 255, 255, 0.4);
          resize: none;
          border-radius: 4px;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      h2 {
        text-align: center;
      }

      .suggestion-buttons {
        display: grid;

        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        mask-image: linear-gradient(to bottom, black 95%, transparent 100%);

        &::-webkit-scrollbar {
          display: none;
        }

        button {
          border-radius: 7px;
          border: transparent;
          background: #de7474;
          color: white;
          padding: 9px;
          text-align: left;

          &:hover {
            transform: scale(103%);
          }

          &:active {
            transform: scale(97%);
          }
        }
      }
    }
  }

  .chat-container {
    z-index: 5;

    margin: 0 auto;
    padding: 5px;
    max-width: 300px;
    width: 300px;
    height: 582px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    border: 2px solid transparent;
    border-radius: 5px;

    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
    word-wrap: break-word;

    mask-image: linear-gradient(to top, black 95%, transparent 100%);

    &::-webkit-scrollbar {
      display: none;
    }

    .chat-bubble {
      border-radius: 15px;
      margin: 5px;
      width: 180px;
      opacity: 90%;

      text-align: left;
      font-size: 14px;
      padding: 10px;
    }
  }
}

/* UTILITY CLASSES */

.hide {
  opacity: 0;
}

.slide-out {
  transform: translateY(40px);
}

.user {
  align-self: flex-end;
  background: #0878f8;
  color: #e7f8f8;
}

.joe {
  align-self: flex-start;
  color: black;
  cursor: pointer;
  background: #e8e8eb;
  &:hover {
    transform: scale(103%);
  }

  &:active {
    transform: scale(97%);
  }
}

/* RESPONSIVE DESIGN */
